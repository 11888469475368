import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import * as moduleActions from "../../../action/moduleActions";
import * as userActions from "../../../action/userActions";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import Ellipse9 from "../../../images/Ellipse9.png";
import camera from "../../../images/camera.svg";
import Swal from "sweetalert2";
import { get } from "lodash";
import {
  renderImageField,
  renderInputField,
  renderRadioButtonField,
} from "../../../common/InputFields";
import Search from "../../common/Search";
import * as departmentActions from "../../../action/departmentActions";
import { getDepartment } from "../../../action/departmentActions";
import MobileNavBar from "../../../components/mobileNavBar";
import { useLocation } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let ModifyCompanyUser = (props) => {
  const { allModulesByCompany, auth, token, user } = props;

  const selectModel = localStorage.getItem("selectedModule");
  const [selectModule, setSelectModule] = useState(
    selectModel === null ? undefined : JSON.parse(selectModel)
  );

  const [profilePic, setProfilePic] = useState(selectModule.profilePic);
  const [department, setDepartment] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [categoryModuleData, setCategoryModuleData] = useState();

  let query = useQuery();

  const searchmode = localStorage.getItem("userSearch");

  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);

  const [formData, setName] = useState({
    name: selectModule.name,
    emailAddress: selectModule.emailAddress,
    department: selectModule.department,
    departmentId: selectModule.departmentId,
    location: selectModule.location,
    employeeId: selectModule.employeeId,
    role: selectModule.role,
    mobileNo: selectModule.mobileNo,
  });
  const [selectedModules, setSelectedModules] = useState([]);
  const [usersMobileNos, setUsersMobileNos] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  var modifyBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
  };

  const [count, setCount] = useState(0); //initial value of this

  useEffect(() => {
    if (count < 2) {
      userActions.getRoles().then((data) => {
        console.log("roles", data.data);
        setRoles(data.data);
      });
      loadAllDepartments();
      props.moduleActions.getAllModulesByCompany(token, user.companyId);

      moduleActions
        .getUserDetailbyUserid(query.get("user_id"))
        .then((result) => {
          setSelectedModules(result.data);
          console.log("modules data", result.data);
          const categoryVal = result.data.userCategories.map(
            (item) => item.category
          );
          setCategoryData(categoryVal);
        });

      userActions
        .getUsersMobileNo()
        .then((res) => setUsersMobileNos(res.data || []));
      setCount(count + 1);
    }
  }, [allModulesByCompany, selectModule]);

  const loadAllDepartments = () => {
    departmentActions
      .getAllDepartments()
      .then((response) => {
        setAllDepartments(response.data);
        if (user.role == "Manager") {
          let updatedDepartments = [];
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < user.departmentId.length; j++) {
              if (user.departmentId[j] == response.data[i]._id) {
                updatedDepartments.push(response.data[i]);
              }
            }
          }
          setAllDepartments(updatedDepartments);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(error)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  useEffect(() => {
    try {
      moduleActions.getGroupDetail(categoryData).then((response) => {
        console.log(
          "get data resposne",
          response.data.modules.map((item) => item.moduleName)
        );
        const data = response.data.modules;
        setCategoryModuleData(data);
      });
    } catch (error) {
      console.log(error);
    }
  }, [allModulesByCompany, selectModule]);

  const [pin, setPin] = useState();

  const handleFormSubmit = async (e) => {
    console.log("depart name", departmentName);

    if (
      role !== "Manager" &&
      (!departmentName || departmentName.trim() === "")
    ) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Department field is required!",
      });
      return; // Stop submission if department is not selected.
    }

    if (
      role === "Manager" &&
      (!selectedDepartments || selectedDepartments.length == 0)
    ) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Department field is required!",
      });
      return; // Stop submission if department is not selected.
    }
    const modules = [];
    selectedModules.modules &&
      selectedModules.modules.map((item, index) => {
        if (item.moduleId) {
          modules.push({ moduleId: item?.moduleId?._id, status: true });
        }
      });
    const companyId = props.user.companyId;
    const values = {
      _id: selectModule._id,
      status: selectModule.status,
      firstTimeLogin: selectModule.firstTimeLogin,
      changePasswordAtFirstLogin: selectModule.changePasswordAtFirstLogin,
      name: formData.name,
      emailAddress: formData.emailAddress,
      // department: formData.department,
      employeeId: formData.employeeId,
      location: formData.location,
      role: role,
      companyId: companyId,
      // departmentId: selectModule.departmentId,
      modules: modules,
      mobileNo: formData.mobileNo,
      __v: selectModule.__v,
      profilePic: profilePic,
      string: selectModule.string,
      pin: pin,
    };

    // if (role == "Manager") {
    //   values.departments = selectedDepartments;
    // } else {
    //   values.department = departmentName || formData.department[0];
    // }
    values.department = departmentName || formData.department[0];

    userActions
      .modifyUser(auth.token, values)
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "User Modified Successfully",
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(function () {
          history.push("/user-management");
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: `Error: ${
            error.response.data.message
              ? error.response.data.message
              : error.response.data
              ? error.response.data
              : error.message
          }`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };
  const handleChange = (e) => {
    setName({ ...formData, [e.target.name]: e.target.value });
  };

  const onModuleItemSelected = (module) => {
    if (
      !selectedModules.modules.find(
        (selectedModule) => selectedModule._id === module._id
      )
    ) {
      let updatedUser = Object.assign({}, selectedModules);
      selectedModules.modules.push({
        status: true,
        score: 0,
        moduleId: module,
      });
      setSelectedModules(updatedUser);
    }
  };
  const onRemoveModuleClick = (e, moduleID) => {
    // e.stopPropagation();
    let updatedModules = selectedModules.modules.filter(
      (obj) => obj !== undefined
    );
    setSelectedModules(updatedModules);
    const newSelectedModules = selectedModules.modules.filter(
      (item) => item?.moduleId?._id.toString() !== moduleID.toString()
    );

    let updatedUser = Object.assign({}, selectedModules);
    updatedUser.modules = newSelectedModules;
    setSelectedModules(updatedUser);
  };

  const clearAllSelectedModules = () => {
    setSelectedModules({ ...selectedModules, modules: [] });
  };

  const onRemoveDepartmentClick = (e, departmentId) => {
    e.stopPropagation();
    const newSelectedDepartments = selectedDepartments.filter(
      (item) => item._id !== departmentId
    );
    setSelectedDepartments(newSelectedDepartments);
  };

  const onUpload = (event) => {
    userActions
      .uploadFile(auth.token, get(event, "target.files[0]"))
      .then((res) => {
        setProfilePic(res);
      });
  };

  // const [role, setRole] = useState(formData.role);
  const [role, setRole] = useState(selectModule.role || "");

  let [roles, setRoles] = useState([]);

  const handleRoleChange = (e) => {
    console.log("selected role", e.target.value);
    setRole(e.target.value);

    if (e.target.value !== "Manager") {
      setdepartmentName(selectedDepartments[0].departmentName);
    }
  };

  let [departmentName, setdepartmentName] = useState(null);
  let [allDepartments, setAllDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  useEffect(() => {
    let selectedDepartmentsList = [];
    for (let i = 0; i < selectModule.departmentId.length; i++) {
      for (let j = 0; j < allDepartments.length; j++) {
        if (selectModule.departmentId[i] === allDepartments[j]._id) {
          selectedDepartmentsList.push(allDepartments[j]);
        }
      }
    }
    setSelectedDepartments(selectedDepartmentsList);
    if (selectModule.role !== "Manager" && selectedDepartmentsList.length > 0) {
      setdepartmentName(formData.department[0]);
    }
  }, [allDepartments, selectModule]);

  const handleChangeDrop = (event) => {
    console.log("dep", event.target.value);
    setdepartmentName(event.target.value);
    // setSelectedUsers([...selectedUsers, user]);
  };
  const onDepartmentItemSelected = (department) => {
    if (
      !selectedDepartments.find(
        (selectedDepartment) => selectedDepartment._id === department._id
      )
    ) {
      setSelectedDepartments([...selectedDepartments, department]);
    }
  };

  const deleteUser = (event) => {
    const userId = query.get("user_id");
    Swal.fire({
      allowOutsideClick: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6a61f6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      if (result.isConfirmed) {
        userActions.deleteUser(userId).then((responce) => {
          if (responce.data.status) {
            Swal.fire({
              title: "Success",
              text: "User Deleted Successfully",
              icon: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then(function () {
              history.push("/user-management");
            });
          }
        });
      }
    });
  };
  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="right_hading d-none d-sm-inline">
                Modify User (User ID: {selectedModules._id})
              </h2>

              <div className="mt-3">
                <Link
                  to="/user-management"
                  className="adcm-btn ms-2 custm-btn custm-success"
                >
                  <span>Return</span>
                </Link>
                <button
                  type="submit"
                  className="btn adcm-btn ms-2 custm-btn"
                  style={{ color: "#fff" }}
                  onClick={() => handleFormSubmit()}
                >
                  Save
                </button>
                <button
                  onClick={() => deleteUser()}
                  className="btn ms-2 adcm-btn custm-btn custm-danger"
                >
                  Delete User
                </button>
              </div>
            </div>
            <h2 className="right_hading d-sm-none d-block">Dashboard</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="company-main">
                  <div
                    className="company-profile"
                    style={modifyBackgroundStyle}
                  ></div>
                  <div className="company-fillout">
                    <div className="circle-outer">
                      <div className="circle">
                        {/* <img src={Ellipse9} alt="" /> */}
                        <img
                          className="profile-pic"
                          src={get(profilePic, "Location") || Ellipse9}
                          alt=""
                        />
                      </div>
                      <div className="p-image">
                        {/* <img className="upload-button" src="assets/images/camera.svg" alt="" /> */}
                        <img className="profile-pic" src={camera} alt="" />

                        {/* <input className="file-upload" type="file" accept="image/*" /> */}
                        <div
                          className="photoEdit mt-3"
                          style={{ position: "relative" }}
                        >
                          <input
                            onChange={onUpload}
                            accept={".jpg, .jpeg, .png"}
                            type="file"
                            className="overlaydpchange"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <h3 className="fill-heaing">User Information</h3>
                    <div className="row ">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>User Name</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="User name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          <div className="form-group">
                            <label for="exampleInputName2">Pin</label>
                            <input
                              name="emailAddress"
                              component={renderInputField}
                              type="password"
                              label="Pin"
                              placeholder="Enter Pin"
                              className="form-control"
                              onChange={(e) => setPin(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="exampleInputName2">Email ID</label>
                          <input
                            type="text"
                            name="emailAddress"
                            className="form-control"
                            placeholder="Enter email"
                            value={formData.emailAddress}
                            autocomplete="off"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="row">
                          <div className="form-group">
                            <label for="exampleInputName2">User ID </label>
                            <input
                              type="number"
                              name="mobileNo"
                              className="form-control"
                              placeholder="User ID"
                              value={formData.mobileNo}
                              autocomplete="off"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="company-fillout snd">
                    <h3 className="fill-heaing">Additional Information</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Role</label>
                          <select
                            className="form-control select2"
                            onChange={handleRoleChange}
                          >
                            {roles?.map((item) => {
                              if (formData.role == item.role) {
                                return (
                                  <option
                                    value={item.role}
                                    key={item._id}
                                    selected
                                  >
                                    {item.role}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={item.role} key={item._id}>
                                    {item.role}
                                  </option>
                                );
                              }
                            })}

                            {/* {roles?.map((item) => {
                              if (user.role === "Manager") {
                                if (item.role === "User") {
                                  return (
                                    <option value={item.role} key={item._id}>
                                      {item.role}
                                    </option>
                                  );
                                }
                              } else if (user.role !== "Manager") {
                                return (
                                  <option value={item.role} key={item._id}>
                                    {item.role}
                                  </option>
                                );
                              }
                            })} */}
                          </select>
                        </div>

                        <div className="form-group">
                          <label>Course</label>
                          {/* <input type="text" className="form-control" placeholder="1234567890" autocomplete="off" /> */}
                          <input
                            type="text"
                            name="employeeId"
                            className="form-control"
                            placeholder="Course Name"
                            value={formData.employeeId}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          {/* {role == "Manager" ? (
                            <>
                              <div
                                className="flex2fieldForm"
                                style={{ marginTop: "3.65em" }}
                              >
                                <div className="field-group">
                                  <label>Department</label>
                                  <select
                                    disabled
                                    className="form-control select2"
                                    onChange={handleChangeDrop}
                                    value={
                                      departmentName ||
                                      (formData.department &&
                                        formData.department[0]) ||
                                      ""
                                    }
                                  >
                                    <option value="">Select Department</option>
                                    {allDepartments &&
                                      allDepartments.length > 0 &&
                                      allDepartments.map((result, i) => {
                                        if (
                                          result.departmentName ==
                                          formData.department[0]
                                        ) {
                                          return (
                                            <option
                                              value={result.departmentName}
                                              key={result._id}
                                              selected
                                            >
                                              {result.departmentName}
                                            </option>
                                          );
                                        } else {
                                          return (
                                            <option
                                              value={result.departmentName}
                                              key={result._id}
                                            >
                                              {result.departmentName}
                                            </option>
                                          );
                                        }
                                      })}
                                  </select>
                                </div>
                              </div>
                              <div className="flex2fieldForm">
                                <div
                                  className="field-group"
                                  style={{ marginTop: "3.5em" }}
                                >
                                  {selectedDepartments &&
                                    selectedDepartments.length > 0 && (
                                      <div className="flex2fieldForm">
                                        <div className="field-group">
                                          <div className="radioGroup">
                                            {selectedDepartments.map(
                                              (item, index) => (
                                                <div
                                                  key={`${item}-${index}`}
                                                  className="mr-4 moduleCompetencyCard"
                                                >
                                                  <label htmlFor="Module">
                                                    {item.departmentName}
                                                  </label>
                                                  <button
                                                    onClick={(e) =>
                                                      onRemoveDepartmentClick(
                                                        e,
                                                        item._id
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </>
                          ) : ( */}
                          <>
                            <label style={{ marginTop: "1.65em" }}>
                              Department
                            </label>
                            <select
                              className="form-control select2"
                              onChange={handleChangeDrop}
                              value={
                                departmentName ||
                                (formData.department &&
                                  formData.department[0]) ||
                                ""
                              }
                            >
                              <option value="">Select Department</option>
                              {allDepartments &&
                                allDepartments.length > 0 &&
                                allDepartments.map((result, i) => {
                                  if (
                                    result.departmentName ==
                                    formData.department[0]
                                  ) {
                                    return (
                                      <option
                                        value={result.departmentName}
                                        key={result._id}
                                        selected
                                      >
                                        {result.departmentName}
                                      </option>
                                    );
                                  } else {
                                    return (
                                      <option
                                        value={result.departmentName}
                                        key={result._id}
                                      >
                                        {result.departmentName}
                                      </option>
                                    );
                                  }
                                })}
                            </select>
                          </>
                          {/* )} */}
                        </div>

                        {/* <div className="form-group">
                            <label>Department</label>
                            <input
                              type="text"
                              name="department"
                              className="form-control"
                              placeholder="User name"
                              value={formData.department}
                              onChange={handleChange}
                            />
                          </div> */}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Location</label>
                          {/* <input type="text" className="form-control" placeholder="Mumbai" autocomplete="off" /> */}
                          <input
                            type="text"
                            name="location"
                            className="form-control"
                            placeholder="Location"
                            value={formData.location}
                            onChange={handleChange}
                          />
                        </div>
                        {role !== "Manager" && (
                          <div className="form-group">
                            <div
                              className="flex2fieldForm"
                              style={{ marginTop: "3.65em" }}
                            >
                              <div className="field-group">
                                <label>Modules</label>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Search
                                    {...{
                                      data: allModulesByCompany,
                                      onItemSelected: onModuleItemSelected,
                                      populateFieldName: "moduleName",
                                      placeholderLabel: "Select Modules",
                                    }}
                                  />
                                  <button
                                    onClick={clearAllSelectedModules}
                                    style={{
                                      marginLeft: "20px",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    aria-label="Clear all selected modules"
                                  >
                                    <FaTrash color="red" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex2fieldForm">
                              <div className="field-group">
                                {selectedModules.modules &&
                                  selectedModules?.modules?.length > 0 && (
                                    <div className="flex2fieldForm">
                                      <div className="field-group">
                                        <div
                                          className="radioGroup"
                                          style={{ marginTop: "3em" }}
                                        >
                                          {selectedModules.modules.map(
                                            (item, index) => {
                                              if (item.moduleId) {
                                                return (
                                                  <div
                                                    key={`${item}-${index}`}
                                                    className="mr-4 moduleCompetencyCard"
                                                  >
                                                    <label htmlFor="Module">
                                                      {
                                                        item?.moduleId
                                                          ?.moduleName
                                                      }
                                                    </label>
                                                    <button
                                                      onClick={(e) => {
                                                        onRemoveModuleClick(
                                                          e,
                                                          item?.moduleId?._id
                                                        );
                                                      }}
                                                    >
                                                      <RxCross2 size={20} />
                                                    </button>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                        {/* <div className="form-group">
                            <label>Role</label>
                            <input
                              type="text"
                              name="role"
                              className="form-control"
                              placeholder="User name"
                              value={formData.role}
                              onChange={handleChange}
                            />
                          </div> */}
                      </div>

                      {/* {role !== "Manager" && (
                          <div className="form-group">
                            <div
                              className="flex2fieldForm"
                            >
                              <div className="field-group">
                                <label>Assigned Modules</label>
                              </div>
                            </div>
                            <div className="flex2fieldForm">
                              <div className="field-group">
                                {categoryModuleData &&
                                  categoryModuleData.length > 0 && (
                                    <div className="flex2fieldForm">
                                      <div className="field-group">
                                        <div
                                          className="radioGroup"
                                        >
                                          {categoryModuleData.map(
                                            (item, index) => {
                                              if (item.moduleName) {
                                                return (
                                                  <div
                                                    key={`${item}-${index}`}
                                                    className="mr-4 moduleCompetencyCard"
                                                  >
                                                    <label htmlFor="Module">
                                                      {
                                                        item.moduleName
                                                      }
                                                    </label>
                                                    <button
                                                      onClick={(e) => {
                                                        onRemoveModuleClick(
                                                          e,
                                                          item
                                                        ); 
                                                      }}
                                                    >
                                                      <RxCross2 size={20} />
                                                    </button>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                              </div>
                              </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    initialValues: state.users.selectedUser,
    token: state.auth.token,
    allModulesByCompany: state.modules.allModulesByCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyCompanyUser);
